import SwiperCore, { Pagination, Navigation } from 'swiper/core';
import FeaturedLastNews from '@/components/Home/FeaturedLastNews';
import FeaturedNews from '@/components/Home/FeaturedNews';
import { SchemaType } from '@/components/Internals/InternalSchema';
import Layout from '@/components/Layout';
import { AdContainer } from '@/components/Layout/ItemListLastNews/styles';
import { api, mimir, site } from '@/services/api';
import { FeaturedNewsApi, HeadTags, NavBarContentData, TimeLineApi } from '@/shared/types/home.types';
import getConcurrentPromises from '@/utils/getConcurrentPromises';
import { GetServerSideProps } from 'next';
import dynamic from 'next/dynamic';
import { NavBarContent } from '@/components/Navbar';
import FeaturedPlaylists from '@/components/Listing/FeaturedPlaylists';
import { FeaturedTimelineData } from '../[categorias]';
import { VideoPlaylistsProps } from './playlists';

SwiperCore.use([Navigation, Pagination]);

interface LastVideosProps {
  navBarData: NavBarContent;
  tagsData: HeadTags[];
  segmentacoes: [];
  timelineData: FeaturedTimelineData;
  homeData: VideoPlaylistsProps['homeData'];
  schema?: SchemaType;
}

export default function LastVideos({ homeData, navBarData, tagsData, segmentacoes, timelineData, schema = null }: LastVideosProps): JSX.Element {
  const AdsTypeGenerators = dynamic(() => import('@/components/Ads/Google/AdsTypeGenerators'), { ssr: false });
  const ShareButtonNoSSR = dynamic(() => import('@components/Layout/ShareButton'), { ssr: false });

  return (
    <Layout layoutData={{ segmentacoes, tagsData, navBarData, schema }}>
      <FeaturedNews featuredNewsData={timelineData.vitrine} />
      <FeaturedPlaylists
        title="Playlists em Destaque"
        url="/video/playlists/"
        featuredVideosData={homeData.data.playlists
          .filter(playlist => playlist.video && playlist.logo && playlist.thumbnail && playlist.playlist)
          .map(playlist => ({
            playlist: { title: playlist.playlist.titulo, url: playlist.playlist.url },
            video: { title: playlist.video.titulo, description: playlist.video.descricao, url: playlist.video.url },
            logo: playlist.logo,
            backgroundColor: playlist.corPrincipal,
            thumbnail: playlist.thumbnail,
          }))}
      />
      <AdContainer noPadding billboard>
        <AdsTypeGenerators type="billboard/main/listing" />
      </AdContainer>
      <FeaturedLastNews
        featuredLastNewsData={timelineData.timeline}
        rootPage="video"
        textTitle="Últimos Vídeos"
        headingTag="h1"
        routeType="tipo"
        route="video"
      />
      <ShareButtonNoSSR
        title={tagsData.find((headTag: HeadTags) => headTag.tag === 'title').content}
        description={tagsData.find((headTag: HeadTags) => headTag.tag === 'meta').attr.content}
      />
    </Layout>
  );
}

export const getServerSideProps: GetServerSideProps = async context => {
  const { host } = context.req.headers;

  const mainReq = { promise: site.get<FeaturedNewsApi>(`/video/`), link: `/video/` };
  const timelineReq = { promise: mimir.get<TimeLineApi>(`/timelines/ultimas/tipo/video/`), link: `/timelines/ultimas/tipo/video/` };
  const responseMenuReq = { promise: api.get<NavBarContentData>(`/componentes/menu/`), link: `/componentes/menu/` };

  const { promiseData, data, statusCode } = await getConcurrentPromises({
    mainPromise: mainReq,
    navBarPromise: responseMenuReq,
    timelinePromise: timelineReq,
    host,
  });

  if (data.redirect || data.notFound) return data;
  const homeData = data;
  const navBarData: NavBarContentData = promiseData[responseMenuReq.link].data.data;
  const tagsData = data.ssr;
  const { segmentacoes } = data;
  const timelineData = promiseData[timelineReq.link].data.data;

  context.res.setHeader('Cache-Control', data.headers['Cache-control']);
  context.res.setHeader('Expires', data.headers.Expires);
  context.res.statusCode = statusCode;

  return {
    props: {
      tagsData,
      homeData,
      navBarData,
      segmentacoes,
      timelineData,
    },
  };
};
