import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper/core';
import Titulo from '@/components/Layout/Title';
import { getCurrentHub } from '@sentry/nextjs';
import useBreakpoint from '@/hooks/useBreakpoint';
import PlaylistCard, { PlaylistCardProps } from '@/components/Listing/PlaylistCard';

SwiperCore.use([Navigation, Pagination]);
export interface VideoSlideProps {
  featuredVideosData: PlaylistCardProps[];
  title?: string;
  url?: string;
}

export default function FeaturedPlaylists({ featuredVideosData, title = 'Vídeos', url = '/video/' }: VideoSlideProps) {
  const currentSpan = getCurrentHub().getScope()?.getSpan();
  const videoSlidersSpan = currentSpan && currentSpan.startChild({ op: 'videoSliders' });
  const [isMobile] = useBreakpoint('phone');

  const pagination = {
    clickable: true,
  };

  const navigation = {
    nextEl: '#videos-swiper-button__next',
    prevEl: '#videos-swiper-button__prev',
  };

  return (
    <>
      <div className="bg-mglGray50 p-0 desktop:p-10 first:[&>div]:px-6 first:[&>div]:py-0 [&_.swiper-container]:px-6 [&_.swiper-container]:py-0">
        <Titulo texto={title} urlRedirect={url} color="black" showChip alignRight />
        <div className="relative mb-0 mt-4 desktop:mb-10 [&_.container-swiper\_\_next]:right-6 [&_.container-swiper\_\_prev]:left-6 [&_.container-swiper]:z-[5] [&_.container-swiper]:opacity-100 [&_.container-swiper]:duration-300 [&_.container-swiper]:ease-ease [&_.swiper-pagination]:bottom-0 [&_.swiper-pagination]:pr-0 [&_.swiper-pagination]:text-right [&_.swiper-pagination-bullet-active.swiper-pagination-bullet]:[&_.swiper-pagination]:w-[26px] [&_.swiper-pagination-bullet-active.swiper-pagination-bullet]:[&_.swiper-pagination]:bg-mglBlue400 [&_.swiper-pagination-bullet-active.swiper-pagination-bullet]:[&_.swiper-pagination]:[transition:background-color_0.3s_0s,_width_0.2s_0.3s] [&_.swiper-pagination-bullet]:[&_.swiper-pagination]:h-2 [&_.swiper-pagination-bullet]:[&_.swiper-pagination]:w-2 [&_.swiper-pagination-bullet]:[&_.swiper-pagination]:rounded-2xl [&_.swiper-pagination-bullet]:[&_.swiper-pagination]:bg-black/20 [&_.swiper-pagination-bullet]:[&_.swiper-pagination]:[transition:background-color_0.3s_0s,_width_0.2s_0.3s] [&_.swiper-slide]:flex [&_.swiper-wrapper]:gap-0 [&_swiper-pagination-bullets]:pr-6">
          <Swiper
            centeredSlidesBounds
            centeredSlides={isMobile}
            grabCursor
            speed={1000}
            pagination={pagination}
            navigation={navigation}
            breakpoints={{
              0: {
                slidesPerView: 1.5,
                spaceBetween: 16,
                slidesPerGroup: 1,
              },
              530: {
                slidesPerView: 2.5,
                spaceBetween: 16,
                slidesPerGroup: 2,
              },
              980: {
                slidesPerView: 4.5,
                spaceBetween: 16,
                slidesPerGroup: 4,
              },
            }}>
            {featuredVideosData.map(video => (
              <SwiperSlide key={`video-sliders-${video.video.url}`}>
                <PlaylistCard
                  backgroundColor={video.backgroundColor}
                  playlist={video.playlist}
                  thumbnail={video.thumbnail}
                  video={video.video}
                  logo={video.logo}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="container-swiper container-swiper__prev">
            <div id="videos-swiper-button__prev" className="swiper-button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                width="1.5em"
                height="1.5em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
                style={{
                  verticalAlign: '-0.125em',
                  transform: 'rotate(180deg)',
                }}>
                <path d="M8.59 16.58L13.17 12L8.59 7.41L10 6l6 6l-6 6l-1.41-1.42z" fill="currentColor" />
              </svg>
            </div>
          </div>
          <div className="container-swiper container-swiper__next">
            <div id="videos-swiper-button__next" className="swiper-button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                width="1.5em"
                height="1.5em"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
                style={{
                  verticalAlign: '-0.125em',
                  transform: 'rotate(360deg)',
                }}>
                <path d="M8.59 16.58L13.17 12L8.59 7.41L10 6l6 6l-6 6l-1.41-1.42z" fill="currentColor" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      {videoSlidersSpan && videoSlidersSpan.finish()}
    </>
  );
}
