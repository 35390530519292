import ImageComponent from '@/components/Layout/Image';
import Chip from '@/components/Layout/Chip';
import Link from 'next/link';
import { mglGray } from '@/styles/designSystemColors';

export interface PlaylistCardProps {
  playlist: {
    title: string;
    url: string;
  };
  video: {
    title: string;
    description: string;
    url: string;
  };
  backgroundColor?: string;
  logo?: {
    url: string;
    blurhash: string;
  };
  thumbnail: {
    url: string;
    blurhash: string;
  };
}

export default function PlaylistCard({ playlist, video, backgroundColor = mglGray.mglGray800, logo = undefined, thumbnail }: PlaylistCardProps) {
  return (
    <article className="flex w-full flex-col items-center justify-center gap-4 rounded-lg p-7" style={{ backgroundColor }}>
      {logo && logo.url && (
        <Link href={playlist.url} passHref>
          <a
            className="flex h-auto w-[60%] items-center justify-center [&>figure]:aspect-video"
            title={`Ver mais vídeos da playlist ${playlist.title}`}
            href={playlist.url}>
            <ImageComponent src={logo.url} blurhash={logo.blurhash} />
          </a>
        </Link>
      )}
      <Link href={video.url} passHref>
        <a className="flex w-full flex-col items-center justify-center gap-4" title={`Ir para ${video.title}`} href={video.url}>
          <div className="relative h-auto w-full rounded transition-all duration-300 ease-ease after:absolute after:left-[50%] after:top-[50%] after:-ml-[10px] after:-mt-3 after:h-6 after:w-5 after:content-playlist hover:scale-105 focus:scale-105 [&>figure]:aspect-video [&>figure]:[filter:brightness(60%)]">
            <ImageComponent src={thumbnail.url} blurhash={thumbnail.blurhash} />
          </div>
          <p className="line-clamp-3 h-16 w-full text-ellipsis text-sm text-mglGray50 [contain:strict]" title={video.title}>
            {video.title}
          </p>
        </a>
      </Link>
      <Link href={playlist.url} passHref>
        <a title={`Ver mais vídeos da playlist ${playlist.title}`} href={playlist.url}>
          <Chip
            texto="ver playlist"
            color="white"
            borderColor="white"
            title={`Ver mais vídeos da playlist ${playlist.title}`}
            posIcon="chevron-right"
            chipSize="medium"
            hover="black"
          />
        </a>
      </Link>
    </article>
  );
}
